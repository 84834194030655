@import url('https://fonts.googleapis.com/css2?family=Ropa+Sans&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  li {
    font-family: 'Ropa Sans', sans-serif;
    

  }

  /* this button styling is for tabs, every other button will need to be styled with className individually */
  button {
    @apply text-[#DE4646] border bg-white rounded-t-md hover:bg-gray-100
  } 

  h1 {
    font-family: 'Ropa Sans', sans-serif;
  }
}
